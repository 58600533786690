<template>
  <v-container fluid>
    <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
      <v-row>
        <v-col cols="12">
          <v-row class="px-3">
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0 mt-3">
                  <p class="title font-weight-bold mb-0">
                    Configuración de ingresos
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                    <v-row class="px-2">
                      <v-col cols="12" class="pb-8">
                        <!--<v-switch
                          v-model="currentAffiliation.comissionTransfer"
                          label="¿Deseas trasladar la comisión de la pasarela de pago al asistente?"
                          @change="$_updateAffiliation()"
                        ></v-switch>-->
                        <v-switch
                          v-model="currentAffiliation.offerBilling"
                          label="¿Ofreceras opción de facturar?"
                          @change="$_updateAffiliation()"
                          :disabled="
                            !user.permissions.Membership ||
                            !user.permissions.Membership.update
                          "
                        ></v-switch>
                        <v-switch
                          v-if="user.fixedFee"
                          v-model="currentAffiliation.feeBreakdown"
                          label="¿Se mostrará desglose de comisión en el recibo?"
                          @change="$_updateAffiliation()"
                          :disabled="
                            !user.permissions.Membership ||
                            !user.permissions.Membership.update
                          "
                        ></v-switch>
                        <v-switch
                          v-model="canApproveMembers"
                          label="¿Aprobar miembros?"
                          @change="updateCanApproveMembers"
                        ></v-switch>
                        <div>
                          <v-switch
                          v-model="currentAffiliation.installments"
                          label="¿Se aceptan meses sin intereses?"
                          :disabled="
                            !user.permissions.Membership ||
                            !user.permissions.Membership.update
                          "
                        ></v-switch>
                          <!-- @change="$_updateAffiliation()" -->
                        <v-combobox
                          v-model="currentAffiliation.installmentsSelected"
                          :items="interestFeeOptions"
                          :disabled="!currentAffiliation.installments"
                          hide-details
                          rounded
                          filled
                          multiple
                          chips
                          @change="$_updateAffiliation()"
                          label="Meses sin intereses "
                        />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="12" class="pb-0 mt-3 d-flex align-center">
              <p class="title font-weight-bold mb-1 mt-4">
                Preguntas frecuentes
              </p>
              <v-tooltip top max-width="350px">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on" class="ml-2 mt-4"
                    >mdi-help-circle</v-icon
                  >
                </template>
                <span
                  >En este apartado podrás agregar las preguntas que comúnmente
                  te hacen tus miembros.<br />Al momento de que tus miembros
                  ingresen a tu micrositio podrán ver estas preguntas.</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                <v-row class="px-2 pb-2">
                  <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    class="pb-3"
                    v-for="(faq, index) in affiliationFaqs"
                    :key="index"
                  >
                    <v-form
                      :ref="`faqForm${index}`"
                      v-model="validFaqsForm[index]"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            v-model="faq.question"
                            label="Pregunta"
                            filled
                            rounded
                            :disabled="editingFaqId != faq.id"
                            :rules="questionRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            v-model="faq.answer"
                            filled
                            rounded
                            name="input-7-4"
                            label="Respuesta"
                            :disabled="editingFaqId != faq.id"
                            :rules="answerRules"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pt-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="#FF7043"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDeleteFaqDialog(faq)"
                                :disabled="
                                  !user.permissions.Membership ||
                                  !user.permissions.Membership.update
                                "
                              >
                                <v-icon dark> mdi-trash-can-outline </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar pregunta frecuente</span>
                          </v-tooltip>
                          <v-tooltip v-if="editingFaqId == faq.id" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  !validFaqsForm[index] ||
                                  faq.answer == '' ||
                                  faq.question == '' ||
                                  !user.permissions.Membership ||
                                  !user.permissions.Membership.update
                                "
                                @click="$_updateAffiliationFaq(faq)"
                              >
                                <v-icon> mdi-content-save </v-icon>
                              </v-btn>
                            </template>
                            <span>Guardar cambios</span>
                          </v-tooltip>
                          <v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="secondary"
                                v-bind="attrs"
                                v-on="on"
                                @click="enableFaqEdition(faq.id)"
                                :disabled="
                                  !user.permissions.Membership ||
                                  !user.permissions.Membership.update
                                "
                              >
                                <v-icon color="black"> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Editar pregunta frecuente</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" class="pb-3">
                    <v-form
                      ref="newFaqForm"
                      v-model="validNewFaqForm"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            v-model="newFaq.question"
                            label="Pregunta"
                            filled
                            rounded
                            :rules="questionRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            v-model="newFaq.answer"
                            filled
                            rounded
                            name="input-7-4"
                            label="Respuesta"
                            :rules="answerRules"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pt-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                small
                                color="primary"
                                :disabled="
                                  !validNewFaqForm ||
                                  newFaq.answer == '' ||
                                  newFaq.question == '' ||
                                  !user.permissions.Membership ||
                                  !user.permissions.Membership.update
                                "
                                v-bind="attrs"
                                v-on="on"
                                @click="$_createAffiliationFaq()"
                              >
                                <v-icon> mdi-plus </v-icon>
                              </v-btn>
                            </template>
                            <span>Crear pregunta frecuente</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="primaryColorDialog" max-width="400">
        <v-card>
          <p class="headline font-weight-medium px-7 pt-5">
            Selecciona el color primario
          </p>
          <v-card-text class="d-flex justify-center pa-2">
            <v-color-picker
              v-model="primaryColor"
              class="ma-0 ma-md-2"
              mode="hexa"
              hide-mode-switch
              show-swatches
              swatches-max-height="300px"
            ></v-color-picker>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pb-5 pr-5">
            <v-btn
              color="grey lighten-2"
              @click="primaryColorDialog = false"
              :disabled="
                !user.permissions.Membership ||
                !user.permissions.Membership.update
              "
              >Cancelar</v-btn
            >
            <v-btn
              color="accent"
              @click="primaryColorDialog = false"
              :disabled="
                !user.permissions.Membership ||
                !user.permissions.Membership.update
              "
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="secondaryColorDialog" max-width="400">
        <v-card>
          <p class="headline font-weight-medium px-7 pt-5">
            Selecciona el color secundario
          </p>
          <div class="d-flex justify-center pa-2">
            <v-color-picker
              v-model="secondaryColor"
              class="ma-0 ma-md-2"
              mode="hexa"
              hide-mode-switch
              show-swatches
              swatches-max-height="300px"
            ></v-color-picker>
          </div>
          <v-card-actions class="d-flex justify-end pb-5 pr-5">
            <v-btn
              color="grey lighten-2"
              @click="secondaryColorDialog = false"
              :disabled="
                !user.permissions.Membership ||
                !user.permissions.Membership.update
              "
              >Cancelar</v-btn
            >
            <v-btn
              color="accent"
              @click="secondaryColorDialog = false"
              :disabled="
                !user.permissions.Membership ||
                !user.permissions.Membership.update
              "
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- INICIO Dialog para confirmar la eliminación de una pregunta frecuente -->
      <v-dialog v-model="deleteFaqDialog" width="600">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="220"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-2">
                <p class="text-center font-weight-bold headline mb-0">
                  Eliminar pregunta frecuente
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="title text-center mb-0">
                  ¿Está seguro que quiere eliminar el registro seleccionado?
                </p>
                <p class="subtitle-1 text-center">
                  La pregunta no podrá ser recuperada a menos que se cree otra
                  igual.
                </p>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="font-weight-bold black--text mx-2"
                color="grey lighten-3"
                rounded
                elevation="0"
                @click="deleteFaqDialog = false"
                :disabled="!user.permissions.Membership || !user.permissions.Membership.update"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="font-weight-bold black--text mx-2"
                color="accent"
                rounded
                elevation="0"
                @click="$_deleteAffiliationFaq()"
                :disabled="!user.permissions.Membership || !user.permissions.Membership.update"
              >
                Aceptar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- FIN Dialog para confirmar la eliminación de una pregunta frecuente -->
    </v-card>
  </v-container>
</template>
<script>
import { validations } from "@/share/validations.js";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("affiliation", ["affiliationSelected", "affiliationFaqs"]),
    ...mapState("user", ["user"]),
    /**
     * Función para tomar la fecha actual y ponerla en los v-date-picker.
     */
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    /**
     * Función para dar formato de la fecha inicial de la membresía.
     */
    initialDateFormatted() {
      if (this.isNewAffiliation)
        return this.formatDate(
          this.currentAffiliation.dateConfiguration.startDate
        );
      else {
        return this.formatDate(
          this.currentAffiliation.dateConfiguration.startDate.substring(0, 10)
        );
      }
    },
    /**
     * Función para dar formate de la fecha final del membresía.
     */
    finalDateFormatted() {
      if (this.isNewAffiliation)
        return this.formatDate(
          this.currentAffiliation.dateConfiguration.endDate
        );
      else {
        return this.formatDate(
          this.currentAffiliation.dateConfiguration.endDate.substring(0, 10)
        );
      }
    },
  },
  data() {
    return {
      tab: "affiliationConfiguration",
      dateMenu1: false,
      dateMenu2: false,
      timeMenu1: false,
      timeMenu2: false,
      modalityCategories: [
        {
          text: "Presencial",
          value: "PRESENTIAL",
        },
        {
          text: "En línea",
          value: "VIRTUAL",
        },
        {
          text: "Híbrido",
          value: "HYBRID",
        },
      ],
      interestFeeOptions: [
        {
          text: "3 meses",
          value: 3
        },
        {
          text: "6 meses",
          value: 6
        },
        {
          text: "9 meses",
          value: 9
        },
        {
          text: "12 meses",
          value: 12
        },
        {
          text: "18 meses",
          value: 18
        },
        {
          text: "24 meses",
          value: 24
        },
      ],
      /*
      typeCategories: [
        {
          text: "Concierto",
          value: "Concert",
        },
        {
          text: "Conferencia",
          value: "Conference",
        },
        {
          text: "Congreso",
          value: "Congres",
        },
        {
          text: "Affiliationo empresarial",
          value: "Empresarial Affiliation",
        },
        {
          text: "Feria",
          value: "Fair",
        },
        {
          text: "Foro",
          value: "Forum",
        },
        {
          text: "Obra de Tetro",
          value: "Play",
        },
        {
          text: "Simposio",
          value: "Symposium",
        },
        {
          text: "Taller",
          value: "Workshop",
        },
        {
          text: "Otro",
          value: "Other",
        },
      ],
      personalizationCategories: [
        {
          item: "Académico",
          value: "Academic",
        },
        {
          item: "Arte",
          value: "Art",
        },
        {
          item: "Bienestar",
          value: "Welfare",
        },
        {
          item: "Capacitación",
          value: "Training",
        },
        {
          item: "Obra Benefica",
          value: "Charity",
        },
        {
          item: "Cine",
          value: "Cinema",
        },
        {
          item: "Gastronomía",
          value: "Gastronomy",
        },
        {
          item: "Cultura",
          value: "Culture",
        },
        {
          item: "Deporte",
          value: "Sport",
        },
        {
          item: "Emprendimiento",
          value: "Entreprenueship",
        },
        {
          item: "Empresarial",
          value: "Empresarial",
        },
        {
          item: "Politica",
          value: "Politic",
        },
        {
          item: "Publicidad",
          value: "Advertising",
        },
        {
          item: "Religión",
          value: "Religion",
        },
        {
          item: "Social",
          value: "Social",
        },
        {
          item: "Otro",
          value: "Other",
        },
      ],
      */
      affiliationType: [
        "Concierto",
        "Conferencia",
        "Congreso",
        "Convención",
        "Affiliationo empresarial",
        "Feria",
        "Foro",
        "Obra de teatro",
        "Simposio",
        "Taller",
        "Otro",
      ],
      affiliationCategory: [
        "Académico",
        "Arte",
        "Bienestar",
        "Capacitación",
        "Obra benéfica",
        "Cine",
        "Gastronomía",
        "Concierto",
        "Cultura",
        "Deporte",
        "Emprendimiento",
        "Empresarial",
        "Política",
        "Publicidad",
        "Religión",
        "Social",
        "Otro",
      ],
      affiliationAmenities: [
        "Estacionamiento",
        "Coffee Break",
        "Alimentos",
        "Aire Acondicionado",
        "Constancia",
        "Cóctel",
        "Valet Parking",
        "Zona Comercial",
      ],
      /*affiliationAmenities: [
        {
          text: "Estacionamiento",
          value: "Parking",
        },
        {
          text: "Coffee Break",
          value: "Coffee Break",
        },
        {
          text: "Alimentos",
          value: "Food",
        },
        {
          text: "Aire Acondicionado",
          value: "Air Conditioner",
        },
        {
          text: "Constancia",
          value: "Constancy",
        },
        {
          text: "Cóctel",
          value: "Coctail",
        },
        {
          text: "Valet Parking",
          value: "Valet Parking",
        },
        {
          text: "Zona Comercial",
          value: "Comertial Zone",
        },
      ],*/
      paymentModality: ["De pago", "Gratis", "Mixta"],
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      // Documentos
      primaryColorDialog: false,
      secondaryColorDialog: false,
      primaryColor: "#000000",
      secondaryColor: "#000000",
      canApproveMembers: false,
      // Copia del objeto de membresía
      currentAffiliation: {
        _id: "",
        published: false,
        banner: "",
        name: "",
        description: "",
        youtubeVideo: "",
        emailContact: "",
        installmentsSelected: [],
        micrositeConfiguration: {
          link: "",
          primaryColor: "",
          secondaryColor: "",
          buttonColor: "",
          textButtonColor: "black",
        },
        presentialAffiliationConfiguration: {
          name: "",
          city: "",
          country: "",
          formattedAddress: "",
          latitude: null,
          longitude: null,
        },
        socialMedia: {
          facebook: "",
          instagram: "",
          linkedin: "",
          twitter: "",
          youtubeChanel: "",
        },
      },
      place: null,
      newFaq: {
        faqId: "",
        question: "",
        answer: "",
        affiliation: "",
      },
      newFaqObj: {
        faqId: "",
        question: "",
        answer: "",
        affiliation: "",
      },
      validNewFaqForm: false,
      validFaqsForm: [],
      editingFaqId: null,
      currentDeletedFaq: {
        id: "",
        affiliation: "",
      },
      deleteFaqDialog: false,
      // Rules
      questionRules: validations.questionFieldValidation({
        name: "campo",
        maxLength: 250,
      }),
      answerRules: validations.answerFieldValidation({ name: "campo" }),
    };
  },
  watch: {
    async "currentAffiliation.installments"(enable) {
      if(!enable) {
        this.currentAffiliation.installmentsSelected = [];
        await this.$_updateAffiliation();
      }
    }
  },
  methods: {
    ...mapActions("affiliation", [
      "fetchAffiliationById",
      "updateAffiliation",
      "fetchAffiliationFaqs",
      "createAffiliationFaq",
      "updateAffiliationFaq",
      "deleteAffiliationFaq",
    ]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    ...mapActions("user", ["realoadUserData"]),
    async $_fetchAffiliation() {
      await this.fetchAffiliationById(this.$route.params.affiliationId);
      if (
        this.affiliationSelected.presentialAffiliationConfiguration &&
        this.affiliationSelected.presentialAffiliationConfiguration
          .formattedAddress
      ) {
        this.place =
          this.affiliationSelected.presentialAffiliationConfiguration.formattedAddress;
      }
      console.log("affiliationSelected");
      console.log(this.affiliationSelected);
      console.log(this.affiliationFaqs);
      await this.fetchAffiliationFaqs(this.$route.params.affiliationId);
      this.currentAffiliation = this.affiliationSelected;
      if (this.currentAffiliation?.installmentsSelected?.length != 0) {
        this.currentAffiliation.installmentsSelected = [...this.currentAffiliation.installmentsSelected].map(({count}) => ({ text: `${count} meses`, value: count } ))
      };
      await this.realoadUserData({ email: this.user.email })
      this.canApproveMembers = this.user && this.user.canApproveMembers ? this.user.canApproveMembers : false;
    },
    async $_updateAffiliation() {
      this.loading();
      console.log("Imprimir los datos de una membresía");
      console.log(this.currentAffiliation);
      let response = await this.updateAffiliation(this.currentAffiliation);
      console.log("Respuesta al actualizar membresía");
      console.log(response);
      await this.$_fetchAffiliation();
      this.show({
        text: response.message,
        color: response.status ? "primary" : "error",
      });
      this.loaded();
    },
    async $_createAffiliationFaq() {
      this.loading();
      this.newFaq.affiliationId = this.$route.params.affiliationId;
      let response = await this.createAffiliationFaq(this.newFaq);
      await this.fetchAffiliationFaqs(this.$route.params.affiliationId);
      this.newFaq = JSON.parse(JSON.stringify(this.newFaqObj));
      this.show({
        text: response.message,
        color: response.status ? "primary" : "error",
      });
      this.loaded();
    },
    async $_updateAffiliationFaq(faqData) {
      this.loading();
      let response = await this.updateAffiliationFaq(faqData);
      await this.fetchAffiliationFaqs(this.$route.params.affiliationId);
      this.newFaq = JSON.parse(JSON.stringify(this.newFaqObj));
      this.show({
        text: response.message,
        color: response.status ? "primary" : "error",
      });
      this.loaded();
    },
    async $_deleteAffiliationFaq() {
      this.loading();
      this.deleteFaqDialog = false;
      let response = await this.deleteAffiliationFaq(this.currentDeletedFaq);
      await this.fetchAffiliationFaqs(this.$route.params.affiliationId);
      this.show({
        text: response.message,
        color: response.status ? "primary" : "error",
      });
      this.loaded();
    },
    enableFaqEdition(faqId) {
      this.editingFaqId = faqId;
    },
    openDeleteFaqDialog(faqData) {
      this.currentDeletedFaq.id = faqData.id;
      this.currentDeletedFaq.affiliation = faqData.affiliation;
      this.deleteFaqDialog = true;
    },
    /**
     * Función para dar formato de fecha YYYY-MM-DD.
     * @param {String} date Fecha inicial o final del membresía.
     */
    formatDate(date) {
      if (!date) return null;
      //
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    /**
     * Función para guardar dirección del membresía una vez que ha sido ingresada en el campo de dirección de membresía.
     */
    async saveAddress(place) {
      let {
        geometry: {
          location: { lat },
        },
        geometry: {
          location: { lng },
        },
        formatted_address,
        name,
      } = place;
      let flag = isNaN(place.address_components.slice(-1)[0].short_name)
        ? true
        : false;
      let components = [];
      if (flag) components = [...place.address_components].slice(-3);
      else components = [...place.address_components].slice(-4);

      this.currentAffiliation.presentialAffiliationConfiguration = {
        name,
        city: components[0].long_name,
        country: components[2].long_name,
        formattedAddress: formatted_address,
        latitude: lat(),
        longitude: lng(),
        //state: components[1].short_name,
      };
      this.place = formatted_address;
    },
    async updateCanApproveMembers() {
      try {
        const response = await this.axios.put('/users/update-can-approve-members', {
          canApproveMembers: this.canApproveMembers,
        });

        if (response.status === 200) {
          this.show({
            color: "primary", 
            text:'El estado se ha actualizado correctamente.'});
        } else {
          this.show({ 
            color: "error",
            text: 'Hubo un error al actualizar el estado.'});
        }
      } catch (error) {
        console.error('Error al actualizar canApproveMembers:', error);
        this.show({ 
          color: "error",
          text: 'Error de conexión.'
        });
      }
    },
  },
  async mounted() {
    this.loading();
    await this.$_fetchAffiliation();
    this.loaded();
  },
};
</script>
<style scoped>
.little-border {
  border: 2px solid #f0f0f0;
  border-radius: 15px;
}
</style>